<template>
  <div class="page_bg comfirmInventory">
    <!-- 顶部卡片信息 -->
    <div class="comfirmInventory_top_bg">
      <div class="company_info">
        <div class="info_name">{{ takingInfos.companyName }}</div>
        <div class="info_order_no_time">订单编号：{{ takingInfos.backendTakingNo || takingInfos.frontendTakingNo }}</div>
        <div class="info_order_no_time">盘存时间：{{ takingInfos.createTime }}</div>
      </div>
      <div class="company_info_card flex-r-sa-c" style="margin-top:0.53rem">
        <div class="card_item">
          <div class="card_item_money">{{ takingInfos.goodsBalance || takingInfos.stockBalance }}</div>
          <div class="card_item_msg">期初总额(元)</div>
        </div>
        <div class="card_line" />
        <div class="card_item">
          <div class="card_item_money">{{ takingInfos.categoryNum || takingInfos.stockCategory }}</div>
          <div class="card_item_msg">存货种类</div>
        </div>
        <div class="card_line" />
        <div class="card_item">
          <div class="card_item_money">{{ takingInfos.goodsNum || takingInfos.stockNum }}</div>
          <div class="card_item_msg">存货数量</div>
        </div>
      </div>
      <div class="company_info_card flex-r-sa-c">
        <div class="card_item">
          <div class="card_item_money">{{ takingInfos.originalTotalMoney }}</div>
          <div class="card_item_msg">消耗总额(元)</div>
        </div>
        <div class="card_line" />
        <div class="card_item">
          <div class="card_item_money">{{ takingInfos.consumeGoodsCategory || takingInfos.consumeCategory }}</div>
          <div class="card_item_msg">消耗种类</div>
        </div>
        <div class="card_line" />
        <div class="card_item">
          <div class="card_item_money">{{ takingInfos.consumeGoodsSum || takingInfos.consumeSum }}</div>
          <div class="card_item_msg">消耗数量</div>
        </div>
      </div>
      <div class="company_info_card flex-r-sa-c">
        <div class="card_item">
          <div class="card_item_money">{{ takingInfos.totalMoney }}</div>
          <div class="card_item_msg">待结总额(元)</div>
        </div>
        <div class="card_line" />
        <div class="card_item">
          <div class="card_item_money">{{ takingInfos.vipDiscountPrice }}</div>
          <div class="card_item_msg">折扣金额</div>
        </div>
        <div class="card_line" />
        <div class="card_item">
          <div class="card_item_money">{{ takingInfos.vipDiscountBenefits }}</div>
          <div class="card_item_msg">折扣权益</div>
        </div>
      </div>
      <div class="company_info_card flex-r-sa-c">
        <div class="card_item">
          <div class="card_item_money">{{ takingInfos.remainTotalMoney || takingInfos.takingGoodsMoney }}</div>
          <div class="card_item_msg">剩余总额(元)</div>
        </div>
        <div class="card_line" />
        <div class="card_item">
          <div class="card_item_money">{{ takingInfos.remainGoodsCategory || takingInfos.takingGoodsCategory }}</div>
          <div class="card_item_msg">剩余种类</div>
        </div>
        <div class="card_line" />
        <div class="card_item">
          <div class="card_item_money">{{ takingInfos.remainGoodsSum || takingInfos.takingGoodsSum }}</div>
          <div class="card_item_msg">剩余数量</div>
        </div>
      </div>

      <!-- 商品列表信息 -->
      <div class="link_pro_listInventory">
        <!-- tab切部分 -->
        <div class="bodyxx">
          <div :class="tabIndex === 1 || tabIndex === 3 ? 'left_tab tabCard':'right_tab tabCard' ">
            <span v-if="parseInt(type) === 2" class="tab1" @click="changeTab(1)">本期消耗 </span>
            <span v-if="parseInt(type) === 1" class="tab1" @click="changeTab(3)">本期消耗 </span>
            <span class="tab2" @click="changeTab(2)">本期剩余 </span>
          </div>
        </div>
        <div class="listInventory_list">
          <MyscrollList ref="myList01" :loading="listLoading" :list="proList" :line-type="1" @myload="proload">
            <template #default="slotProps">
              <div class="link_pro_list_items flex-c-s-s">
                <div class="items_indexNameNum ">
                  <div class="items_indexName flex-r-s-c">
                    <div class="items_index">{{ slotProps.index+1 }}</div>
                    <div class="items_name van-ellipsis">{{ slotProps.item.productName }}</div>
                  </div>
                </div>
                <div class="items_info" style="width:100%">
                  <van-row gutter="5px">
                    <van-col :span="24">
                      <van-col :span="11">
                        <div class="items_info_msg van-ellipsis"><span style="color:#999999">品牌</span>
                          {{ slotProps.item.productBrand || slotProps.item.brand }}</div>
                      </van-col>
                      <van-col :span="11">
                        <div class="items_info_msg van-ellipsis"><span style="color:#999999">规格</span>
                          {{ slotProps.item.productSpecifications || slotProps.item.specifications }}</div>
                      </van-col>
                    </van-col>
                    <van-col :span="24">
                      <van-col v-if="tabIndex === 1 || tabIndex === 3" :span="11">
                        <div class="items_info_msg van-ellipsis"><span style="color:#999999">消耗</span>
                          {{ slotProps.item.consumeNum }}</div>
                      </van-col>
                      <van-col v-if="tabIndex === 2" :span="11">
                        <div class="items_info_msg van-ellipsis"><span style="color:#999999">单价</span>
                          {{ slotProps.item.salePrice || slotProps.item.price }}</div>
                      </van-col>
                      <van-col :span="11">
                        <div class="items_info_msg van-ellipsis"><span style="color:#999999">剩余数量</span>
                          {{ slotProps.item.takingNum }}</div>
                      </van-col>
                    </van-col>
                    <van-col :span="24">
                      <van-col v-if="tabIndex === 1 || tabIndex === 3" :span="11">
                        <div class="items_info_msg van-ellipsis"><span style="color:#999999">单价</span>
                          {{ slotProps.item.salePrice || slotProps.item.price }}</div>
                      </van-col>
                      <van-col v-if="tabIndex === 1 || tabIndex === 3" :span="11">
                        <div class="items_info_msg van-ellipsis"><span style="color:#999999">销售小计</span>
                          {{ slotProps.item.salePriceSum }}</div>
                      </van-col>
                    </van-col>
                  </van-row>
                </div>
                <van-divider />
              </div>
            </template>
          </MyscrollList>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import './index.scss'
import MyscrollList from '@/components/MyscrollList'
import { backendTakingProductList, backendTakingInfo, selectTakingOrder, takingSurvey, frontendTaking_takingProductList } from '@/api/linkPage'
export default {
  components: { MyscrollList },
  data() {
    return {
      id: '',
      type: '',
      takingInfos: [],
      companyInfos: [],
      tabIndex: 1,
      // 商品列表
      proSearch: { page: 1, limit: 10, backendTakingId: '', storeOrderApiType: '' },
      proList: [],
      listLoading: false
    }
  },
  created() {
    this.id = this.$route.query.btid
    this.type = parseInt(this.$route.query.type)
    if (parseInt(this.type) === 1) {
      this.proSearch.searchType = 3
      this.proSearch.frontendTakingId = this.$route.query.btid
      this.getCompanyInfo1()
    }
    if (parseInt(this.type) === 2) {
      this.proSearch.backendTakingId = this.$route.query.btid
      this.getCompanyInfo2()
    }
    this.getProList()
  },
  methods: {
    // 获取医院、盘存信息
    getCompanyInfo1() {
      var parms = { page: 1, limit: 999, frontendTakingId: this.id }
      takingSurvey(parms).then(res => {
        this.takingInfos = res.data
      })
    },
    // 获取医院、盘存信息
    getCompanyInfo2() {
      var parms = { backendTakingId: this.id }
      backendTakingInfo(parms).then(res => {
        this.takingInfos = res.data
      })
      selectTakingOrder(parms).then(res => {
        this.companyInfos = res.data
      })
    },
    changeTab(index) {
      this.$refs.myList01.load_status_clear()
      this.tabIndex = index
      this.proSearch.usedStatus = index
      this.proSearch.searchType = index
      this.proList = []
      this.proSearch.page = 1
      this.getProList()
    },
    // 商品
    getProList() {
      this.$toast.loading({ message: '加载中...', forbidClick: true, loadingType: 'spinner', duration: 0 })
      if (parseInt(this.type) === 2) {
        backendTakingProductList(this.proSearch).then(res => {
          this.$toast.clear()
          this.$refs.myList01.load_loading(false)
          this.proList = this.proList.concat(res.data.list)
          if (parseInt(res.data.total) === 0) { this.$refs.myList01.load_empty('list', true) } else {
            if (res.data.list.length === 0) { this.$refs.myList01.load_finished(true) } // 数据全部加载完成
          }
        })
      }
      if (parseInt(this.type) === 1) {
        frontendTaking_takingProductList(this.proSearch).then(res => {
          this.$toast.clear()
          this.$refs.myList01.load_loading(false)
          this.proList = this.proList.concat(res.data.list)
          if (parseInt(res.data.total) === 0) { this.$refs.myList01.load_empty('list', true) } else {
            if (res.data.list.length === 0) { this.$refs.myList01.load_finished(true) } // 数据全部加载完成
          }
        })
      }
    },
    proload() {
      this.$refs.myList01.load_loading(true)
      this.$utils.debounce(() => {
        this.proSearch.page = this.proSearch.page + 1
        this.getProList()
        // 加载状态结束
      }, 50)()
    }
  }
}
</script>

<style>
</style>
